@import '../../../node_modules/foundation-sites/scss/util/unit';
@import '../../../node_modules/foundation-sites/scss/util/breakpoint';

$breakpoints: (
        small: 0,
        medium: 640px,
        large: 1024px,
        xlarge: 1200px,
        xxlarge: 1440px,
);

.banner-wrapper {
  .banner{
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    user-select: none;

    p, h1, h2, h3, h4, h5, h6{
      margin: 0;
    }

    .unset-color{
      p, h1, h2, h3, h4, h5, h6{
        color: unset;
      }
    }
    .unset-font-size{
      p, h1, h2, h3, h4, h5, h6{
        font-size: unset;
      }
    }
    .unset-font-family{
      p, h1, h2, h3, h4, h5, h6{
        font-family: unset;
        font-weight: unset;
      }
    }
    .unset-line-height{
      p, h1, h2, h3, h4, h5, h6{
        line-height: unset;
      }
    }

    .background{
      display: block;
      img{
        width: 100%;
        display: block;
      }
    }

    .cta-modal{
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 3;
    }

    &.bg-image{
      .region{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        z-index: 2;

        & > .experience-region{
          overflow: hidden;
        }

        &.top{
          top: 0;
          bottom: unset;
        }
        &.center{
          top: 50%;
          bottom: unset;
          transform: translateY(-50%);
        }
        &.bottom{
          top: unset;
          bottom: 0;
        }
      }

      .opacity-layer{
        background-color: #000;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }

    .region{
      .font-primary {
        font-family: 'Sofia Pro', sans-serif;
        font-weight: 500;
      }
      .font-primary-bold {
        font-family: 'Sofia Pro', sans-serif;
        font-weight: 700;
      }
      .font-secondary {
        font-family: 'Silver South Script', sans-serif;
      }
      .font-tertiary-italic {
        font-family: 'PlayfairDisplay-Italic', sans-serif;
        font-weight: 300 !important;
      }
      .font-tertiary-bold {
        font-family: 'PlayfairDisplay-Bold', sans-serif;
      }

      .cta-component{
        .underlined{
          text-decoration: underline;
        }
      }
      .copy-container, .icon-container{
        display: block;
        &.left{
          margin-right: auto !important;
        }
        &.center{
          margin-right: auto !important;
          margin-left: auto !important;
        }
        &.right{
          margin-left: auto !important;
        }

      }

      @media (min-width: map-get($breakpoints, large)) {
        .disabled-on-desktop{
          display: none;
        }
      }
      @media (max-width: #{map-get($breakpoints, large)-1}) and (min-width: map-get($breakpoints, medium)) {
        .disabled-on-tablet{
          display: none;
        }
      }
      @media (max-width: #{map-get($breakpoints, medium)-1}) and (min-width: map-get($breakpoints, small)) {
        .disabled-on-mobile{
          display: none;
        }
      }
    }
  }

  @include breakpoint (medium) {
    .mobile-only{
      display: none !important;
    }
  }
  @include breakpoint (small only){
    .desktop-only{
      display: none !important;
    }
  }
}
